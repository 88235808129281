import "bootstrap/dist/css/bootstrap.css"
import { createApp } from 'vue'
import {library} from "@fortawesome/fontawesome-svg-core"
import { faHatWizard } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"

import App from './App.vue'
import router from './router'
import store from './store'

import {fas} from "@fortawesome/free-solid-svg-icons"
library.add(fas)
library.add(faHatWizard)
createApp(App).component('fa', FontAwesomeIcon).component('font-awesome-icon', FontAwesomeIcon).use(store).use(router).mount('#app')
import "bootstrap/dist/js/bootstrap.js"