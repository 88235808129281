<template>
    <header class="my-header">
        <nav class="navbar navbar-expand-md navbar-light bg-light shadow-lg fixed-top">
            <div class="container">
                <router-link :to="{name: 'home'}" class="navbar-brand fw-bolder text-secondary">Habibur Rahman Bipu</router-link>
                <ul class="navbar-nav">
                    <li><router-link :to="{name: 'about'}" class="nav-link"> <font-awesome-icon icon="fa-solid fa-address-card" /> About</router-link></li>
                    <li><router-link :to="{name: 'work'}" class="nav-link"> <font-awesome-icon icon="fa-solid fa-person-digging" /> My Work</router-link></li>
                    <li><router-link :to="{name: 'developer'}" class="nav-link"> <fa icon="user"/> Find Developer</router-link></li>
                    <li><router-link :to="{name: 'account'}" class="nav-link"> <fa icon="lock"/> Account</router-link></li>
                </ul>
            </div>
        </nav>
    </header>
</template>

<script>
    export default {
        name: "MyHeader"
    }
</script>

<style scoped>
.my-header{ margin-bottom: 50px; }
</style>