<template>
    <footer class="my-footer">
        <section class="py-5 bg-dark">
            <div class="container">
                <div class="row">
                    <div class="col-md-4">
                        <div class="card card-body rounded-0 h-100">
                            <h4 class="text-center text-muted">Popular Developer</h4>
                            <hr/>
                            <ul class="navbar-nav">
                                <li><a href="" class="nav-link text-muted">Web Development</a></li>
                                <li><a href="" class="nav-link text-muted">Web Design</a></li>
                                <li><a href="" class="nav-link text-muted">Ecommerce CMS Development</a></li>
                                <li><a href="" class="nav-link text-muted">Customized Software Development</a></li>
                                <li><a href="" class="nav-link text-muted">POS Software Development</a></li>
                                <li><a href="" class="nav-link text-muted">Accounting Software Development</a></li>
                                <li><a href="" class="nav-link text-muted">Accounting Software Development</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card card-body rounded-0 h-100">
                            <h4 class="text-center text-muted">Popular Services</h4>
                            <hr/>
                            <ul class="navbar-nav">
                                <li><a href="" class="nav-link text-muted">Web Development</a></li>
                                <li><a href="" class="nav-link text-muted">Web Design</a></li>
                                <li><a href="" class="nav-link text-muted">Ecommerce CMS Development</a></li>
                                <li><a href="" class="nav-link text-muted">Customized Software Development</a></li>
                                <li><a href="" class="nav-link text-muted">POS Software Development</a></li>
                                <li><a href="" class="nav-link text-muted">Accounting Software Development</a></li>
                                <li><a href="" class="nav-link text-muted">Accounting Software Development</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card card-body rounded-0 h-100">
                            <h4 class="text-center text-muted">Popular Job</h4>
                            <hr/>
                            <ul class="navbar-nav">
                                <li><a href="" class="nav-link text-muted">Web Development</a></li>
                                <li><a href="" class="nav-link text-muted">Web Design</a></li>
                                <li><a href="" class="nav-link text-muted">Ecommerce CMS Development</a></li>
                                <li><a href="" class="nav-link text-muted">Customized Software Development</a></li>
                                <li><a href="" class="nav-link text-muted">POS Software Development</a></li>
                                <li><a href="" class="nav-link text-muted">Accounting Software Development</a></li>
                                <li><a href="" class="nav-link text-muted">Accounting Software Development</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-3 bg-light border-top">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <p class="mb-0 text-center text-muted">Design & Develop By <strong>Habibur Rahman Bipu</strong></p>
                    </div>
                </div>
            </div>
        </section>
    </footer>
</template>

<script>
    export default {
        name: "MyFooter"
    }
</script>

<style scoped>

</style>